@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;700;900&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

html { font-family: "Pretendard", "system-ui", sans-serif; }

/*@supports (font-variation-settings: normal) {*/
/*  html { font-family: "Inter var", "system-ui"; }*/
/*}*/

body {
  color: #2A2822;
  /*background-color: #F8F7F2;*/
  margin: 0;
  /* height: 100vh; */
  letter-spacing: 0.01em;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Remove arrows on number input in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows on number input in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F8F7F2;
}

.offset {
  text-underline-offset: 3px;
}

.rotate {
  /*background: url(./images/preloader.svg) no-repeat center center;*/
  border: 2px solid;
  border-image: linear-gradient(90deg, rgba(207,86,246,1) 0%, rgba(207,86,246,0) 0%) 1;
  background: linear-gradient(#fff, #fff) padding-box,
  linear-gradient(90deg, rgba(207,86,246,1) 0%, rgba(207,86,246,0) 100%) border-box;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  object-fit: cover;
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.h-sidebar {
  height: calc(98vh - 56px);
}

.serif {
  font-family: 'Source Serif Pro', serif;
  letter-spacing: 0;
  /*letter-spacing: 0.6px;*/
}
.serif-sidebar {
  font-family: 'Source Serif Pro', serif;
}

/*.container-hover:hover > :not(:hover) {*/
/*  opacity: 0.6;*/
/*}*/

.round {
  width: 350px;
  border-radius: 10px;
  overflow: hidden;
}
.white-gradient {
  background: linear-gradient(180deg, #F8F7F2 0%, rgba(248, 247, 242, 0) 100%);
  transform: rotate(-180deg);
}

.shadow {
  filter: drop-shadow(0px 0px 80px rgba(0, 0, 0, 0.16));
}
.shadow-input {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.04), 0 10px 80px rgba(0, 0, 0, 0.05);
}

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(15, 15, 15, 0.05), inset 0 0 0 1px rgba(15, 15, 15, 0.15);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #282724;
}

.input:hover {
  background: rgba(55, 53, 47, 0.05);
}

.input-checked  {
  background: rgba(55, 53, 47, 0.05);
}

input,textarea {
  width: 80%;
  text-overflow-ellipsis: ellipsis;
  display: block
}


.card-shadow {
  border: 1px solid rgba(40, 39, 36, 0.2);
  box-shadow: 0 17px 40px rgba(0, 0, 0, 0.08);
}
.nav-shadow {
  box-shadow: 0 10px 80px rgba(51, 51, 51, 0.05);
}
/*video, video[poster]{*/
/*  height: 694px;*/
/*  width: 1040px;*/
/*}*/

.reader-keypoints-h {
  height: calc(100vh - 198px);
}

.reader-rightPart-h {
  height: calc(99vh - 119px);
  -webkit-overflow-scrolling: touch
}

.wide-scroll {
  overflow-y: scroll;
}

.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: radial-gradient(farthest-side,#FFFFFF 94%,#0000) top/5px 5px no-repeat, conic-gradient(#0000 30%,#FFFFFF);
  mask: radial-gradient(farthest-side at center, transparent calc(100% - 5px), black 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

@media all and (min-width: 1113px) {
  .reader-leftPart-h {
    height: calc(((100vh - 104px)/2) - 16px);
  }
  .reader-rightPart-h {
    height: calc(100vh - 84px);
  }
}

/*@media all and (min-width: 1440px) {*/
/*  .reader-video {*/
/*    width: 746px;*/
/*    height: 418px;*/
/*  }*/
/*}*/

.height-minheader {
  height: calc(100vh - 60px);
}

form:invalid button {
  /*pointer-events: none;*/
  /*background: rgba(18, 18, 18, 0.04);*/
  /*color: #12121273;*/
}
.custom-outline {
  box-shadow: 0px 1px 2px rgba(15, 15, 15, 0.06), inset 0px 0px 0px 1px rgba(31, 31, 31, 0.16);
}
.custom-outline:focus {
  /*border: 1px solid #D3D3D3;*/
  outline: none;
}
.caret {
  caret-color: #2E7356;
}
.message-loader {
  margin-left: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #E7E7E7;
  box-shadow: 12px 0 #E7E7E7, -12px 0 #E7E7E7;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes flash {
  0% {
    background-color: #9F9F9F;
    box-shadow: 12px 0 #9F9F9F, -12px 0 #D2D2D2;
  }
  50% {
    background-color: #D2D2D2;
    box-shadow: 12px 0 #9F9F9F, -12px 0 #9F9F9F;
  }
  100% {
    background-color: #9F9F9F;
    box-shadow: 12px 0 #D2D2D2, -12px 0 #9F9F9F;
  }
}

@media (min-width: 640px) {
  .wide-scroll {
    overflow-y: scroll;
  }
  .mobile-scroll {
    overflow-y: visible;
  }
}

@media (max-width: 640px) {
  .mobile {
    display: none;
  }
  .mobile-scroll {
    overflow-y: scroll;
  }
  .wide-scroll {
    overflow-y: visible;
  }
  
}

@supports (-webkit-touch-callout: none) {
  .iOSmenu {
    position: fixed;
  }
  .iOSchat-height {
    height: calc(100vh - 216px);
  }
  .reader-rightPart-h {
    height: calc(100vh - 200px);
    -webkit-overflow-scrolling: touch
  }
  .iOSpadding {
    padding-bottom: 44px;
  }
  .stop-scroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}
@supports not (-webkit-touch-callout: none) {
  .iOSmenu {
    bottom: 0;
  }
  .iOSchat-height {
    height: calc(100vh - 136px);
  }
  .iOSpadding {
    padding-bottom: 0;
  }
}

.animate-fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.swiper-pagination-bullet-active {
  background-color: #2E7356 !important;
}

.swiper-container {
  padding-bottom: 30px !important;
}
.swiper-pagination {
  position: absolute !important;
  bottom: 0 !important; 
}

.swiper-button-prev, .swiper-button-next {
  color: white !important;
}
@media screen and ( max-height: 740px ) { 
  .loaderHeight {
    top: 61%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.20;
  }
}

.FadeInElement {
  opacity: 0; /* Initial opacity */
  animation: fadeIn 0.7s ease forwards; /* Apply the animation with a 3s duration */
}
